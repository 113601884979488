<template>
    <virgin/>
    </template>
    
    <script>
    import virgin from '@/components/virgin.vue'
    export default {
        components:{
            virgin
        },
        data() {
            return {
               
    
                }
            }
     
    }
    </script>
    